.Directed{
  
    width: 100%;
    height: 100%;
    color: white;
    width: 100%;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--background-color);
}

.Directed h1{
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    
    color: var(--primary-color);
}
.Directed a{
    color: grey;
    font-family: 'Bricolage Grotesque';
    margin-top: 1rem;
    text-decoration: underline;
    font-weight: 200;
}
.directed-content{
 
    width: 100%;
    margin-top: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.category-grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    width: 100%;
    margin-top: 6rem;
}

/* Small devices (phones) */
@media (max-width: 768px) {
    .Directed h1{
        font-size: 22px;
    }
    .Directed a {
        font-size: 14px;
    }
}