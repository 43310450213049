.Lyrics{
    min-height: 100vh;
    width: 100%;
    color: var(--primary-color);
    width: 100%;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--background-color);
}

.Lyrics h1{
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    color: var(--primary-color);
  
   
}

.lyrics-content{
    max-width: 600px;
    width: 100%;
    margin-top: 6rem;
    text-align: center;
    
   
  
  
}

.Lyrics .track__details-text{
    display: none;
}
.Lyrics .track-details{
    flex-direction: column;
}
.Lyrics .track__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Lyrics .audio-player{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}
.Lyrics button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 1px solid var(--primary-color);
    
    border-radius: 50%;
    background-color: transparent;
    padding: 0.6rem;
   
}
.Lyrics .progress{
    width:500px;
}

.Lyrics input{
    width: 100%;
}

.Lyrics h6{
    display: none;
}
.Lyrics a button{
    display: none;
}


.Lyrics span{
   display: flex;
   align-items:center;
   justify-content: center;
   flex-direction: column;
}



.Lyrics span p{
    margin: 1rem 0;
    font-family: 'Bricolage Grotesque';
    font-weight: 200;
    text-align: start;
    font-size: 16px;
}
.Lyrics img{
    border-radius: 0.6rem;
}

@media (max-width: 768px){
    .Lyrics h1{
        font-size: 22px;
    }
}