.Shows{
    color: var(--primary-color);
    position: relative; 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background: var(--background-color);
  
    
}

.Shows h1{
    font-size: 44px;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
   
  
}

.shows-content{
    max-width: 1600px;
    width: 100%;
    margin-top: 6rem;
    text-align: center;
}

.Shows img{
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top center;

  
}
.global-styles, .eapps-widget-toolbar {
    display: none !important;
  }
.global-styles a{
    display: none !important;
}
.shows-text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Shows button{
    margin-top: 1.6rem;
    padding: 0.5rem 1.5rem;
    background: transparent;
  
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    border: 2px solid var(--primary-color);
    font-family: 'Bricolage Grotesque';
    color: var(--primary-color);
    display: flex;
    align-items: center;
    z-index: 99;
    border-radius: 50rem;
}


/*  Landscape Tablets or Small Laptops*/
@media (min-width: 992px) and (max-width: 1599px) {
    .shows-content{
        width: calc(100% - 200px);
    }
   
  }
  
  /* Medium devices (phones) */
  @media (min-width: 767px) and (max-width: 991px) {
    .shows-content{
        width: calc(100% - 200px);
    }
   

  }
  
  /* Small devices (phones) */
  @media (max-width: 767px) {
    .shows-content{
        width: calc(100% - 200px);
    }
    .Shows h1{
        font-size: 22px;
    }
    .Shows img{
        height: 200px;
    }
     
      
  }
  
  /* Extra Small devices (phones) */
  @media (max-width: 467px) {
    .shows-content{
        width: calc(100% - 100px);
    }
    .Shows h1{
        font-size: 22px;
    }
    .Shows img{
        height: 200px;
    }

    
  }