.App {
  min-height: 100vh;
  position: relative;
  background: var(--background-color);
  overflow: hidden;

  






}
button, a{
  cursor: pointer;
}

.scroll-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  width: 50px;
  margin-top: 6rem;
  z-index: 999;
}
::-moz-selection { /* Code for Firefox */
 
  background: var(--secondary-color);
}

::selection {
  
  background: var(--secondary-color);
}





.scroll-wrapper .scroll-page{
  width: 0.3rem;
  height: 3.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-left: 4rem;
  background: var(--primary-color);

  
  
 
}
.cursor{
  background-color: var(--secondary-color);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}
.Load {
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  z-index: 999999;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 800ms ease;

  



}

.Load h1 {
  font-size: 22px;
  text-align: center;
  color: black;
  font-weight: 600;
  font-family: 'Rubik';
}
.Load.hidden {

  transform-origin: top;
  transform: translateY(-100%);
  
  

}
.loading-bg{
  width: 100%;
  height: 100%;
  background: var(--primary-color);
}
@keyframes ellipsis {
  0% {
    content: '...';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
.Load .ellipsis::after {
  content: '...';
  animation: ellipsis steps(1, end) 2s infinite;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  

  
  
 




  
}


.page-number{
  position:absolute;
  bottom: 0;
  left: 0;


  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  
}
.page-number div{
  width: 1rem;
  height: 5rem;
  background: var(--secondary-color);

}
.page-number h1{
  font-family: "Bricolage Grotesque";
  font-size: 62px;
  font-weight: 600;
  color: white;
  margin-left: 1rem;
}
.scroll {
  position: fixed;
  bottom: 0;
  margin-bottom: 2rem;
  width: 1.6rem;
  height: 2.5rem;
  background: transparent;
  border-radius: 40rem;
  border: 1px solid var(--primary-color);
  

  opacity: 1;
  transition: opacity 5s ease;
  z-index: 996; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.scroll-icon{
  width: 0.3rem;
  height: .6rem;
  background: var(--primary-color);
  border-radius: 4rem;
  animation: scroll 1s infinite alternate-reverse;
}
.scroll-up.film{
  color: var(--background-color);
}


@keyframes scroll {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
.scroll-up{
  position: fixed;
  z-index: 999;
  bottom: 0;
  color: var(--primary-color);
  font-size: 48px;
  right: 0;
  margin-right: 2rem;
  margin-bottom: 1rem;
  animation: scroll 1s infinite alternate-reverse;
}





a {
  text-decoration: none;
 
}

.songs-socials{
  position: fixed;
  right: 0;
  top: 0;
  list-style: none;
  margin-top: 4rem;
  z-index: 9999;


}

.songs-socials li{
  font-size: 1.6rem;
  margin-right: 3rem;
  margin-top: 1rem;

  

  
}
.songs-socials li a{
  color: var(--primary-color);
  transition: all 300ms ease;
 
    
}
.songs-socials li.film a{
  color: var(--background-color);
  transition: all 300ms ease;
 
    
}
.scroll.film{
  border: 1px solid var(--background-color);
  
}

.songs-socials li a:hover{
  color: var(--secondary-color);
}

.menu-icon {
	display: block;
 
	position: fixed;
	width: 50px;
	height: 50px;
	cursor: pointer;
  top: 0;
  left: 0;
  z-index: 995;
  margin-left: 2rem;
 
  margin-top: 2rem;
 

}
.scroll-icon.film{
  background: var(--background-color);
}

.menu-icon__cheeckbox {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	cursor: pointer;
	z-index: 2;
	-webkit-touch-callout: none;
	position: absolute;
	opacity: 0;
}

.menu-icon div {
	margin: auto;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 44px;
	height: 24px;
}
img, video{
  background-color: grey;
}

.menu-icon span {
	position: absolute;
	display: block;
	width: 100%;
	height: 2px;
	background-color: var(--primary-color);
	border-radius: 1px;
	transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}

.menu-icon span.film{
  background-color: var(--background-color);
}

.menu-icon span:first-of-type {
	top: 0;
}

.menu-icon span:last-of-type {
	bottom: 0;
}

.menu-icon.active span:first-of-type,
.menu-icon__cheeckbox:checked + div span {
	transform: rotate(45deg);
	top: 5px;
  background-color: var(--primary-color);

 
}

.menu-icon.active span:last-of-type,
.menu-icon__cheeckbox:checked + div span:last-of-type {
	transform: rotate(-45deg);
	bottom: 5px;
  
}

.menu-icon:hover span:first-of-type,
.menu-icon:hover span:last-of-type,
.menu-icon.active:hover span:first-of-type,
.menu-icon.active:hover span:last-of-type,
.menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
.menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
	width: 32px;
}

.menu-icon:hover span:first-of-type {
	width: 36px;
}

.menu-icon:hover span:last-of-type {
	width: 32px;
}

.loading-bar{
  width: 300px;
  height: 20px;
  background: transparent;
  border: 1px solid var(--primary-color);
  margin: 0.3rem 0;
}

@media (max-width: 1024px) {
	.menu-icon:hover span:first-of-type {
		width: auto;
	}

	.menu-icon:hover span:last-of-type {
		width: auto;
	}
}



@media(max-width: 974px) {
  .wrapper {
    background-size: cover;
  }
}

/*  Small devices (phones) */
@media (max-width: 767px) {
  .Load h1{
    font-size: 16px;
  }
  .App{
    cursor: auto;
    pointer-events: all;
  }
  .songs-socials li{
    display: none;
  
    
  
    
  }
  
}
/* Extra Small devices (phones) */
@media (max-width: 467px) {

 
  .page-number h1{
    overflow-y: hidden;
    font-size: 22px !important;
   
  }
  .page-number{
    margin: 0 ;
    z-index: 999;

  

  }
  .scroll , .scroll-up{
    display: none;
  }
  
  
  
  
  
}