.Sidebar {
    width: 270px;
    height: 100dvh;
    background: #fff2e2;
    z-index: 991;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden; 
    transition: width 700ms ease; 
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0px 2.98px 7.46px 0px rgba(0, 0, 0, 0.3);
   
  
}

.Sidebar > div {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
}

.Sidebar.hidden {
    width: 0; 
}

.Sidebar a h1{
    margin: 1.2rem 0;
    margin-left: 2rem;
}

.Sidebar h1 {
    font-family: 'Rubik';
    font-size: 28px;
    color: var(--primary-color);
    text-transform: uppercase;
  
    transition: opacity 700ms ease; 
    font-weight: 600;
}

.Sidebar.hidden a, .Sidebar.hidden li {
    opacity: 0; 
}


.Sidebar h1, .Sidebar span{
    display: flex;
    align-items: center;
}

.Sidebar span{
    margin-left: 0.5rem;
    font-size: 16px;
}

.Sidebar ul li{
    font-family: 'Poppins';
    font-size: 20px;
    color: var(--primary-color);
    margin: 1.2rem 0;
    margin-left: 2rem;
    transition: opacity 700ms ease; 
    font-weight: 400;
    list-style: none;
}
.Sidebar ul li a{
    font-family: 'Inter';
    font-size: 18px;
    color: var(--primary-color);
    margin: 1.2rem 0;
 
   
    font-weight: 200;
    list-style: none;
}

.Sidebar ul li a::after, .Sidebar ul li::after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: -6px;
  left: 0;
  width: 0%;
  background: var(--primary-color);
  transition: all 200ms ease;
}

 .Sidebar ul li a::after, .Sidebar ul li::after{
  background: var(--primary-color);
}

 .Sidebar ul li a:hover, .Sidebar ul li::after{
  color: var(--primary-color);
}

.Sidebar ul li a {
  position: relative;
  transition: all 400ms ease-in-out;
}


.Sidebar ul li a:hover::after,
.Sidebar ul li:hover::after {
  width: 100%;
  right: 0;
}


.Sidebar ul li:nth-child(2){
    margin-top: 2rem;
}

/* Small devices (phones) */
@media (max-width: 767px) {
    .Sidebar h1 {
        font-size: 18px;
    }
    .Sidebar ul li{
      
        font-size: 14px;
        margin-top: 0.3rem;
      
    }
    .Sidebar ul li a{
 
        font-size: 14px;
        margin-top: 0.3rem;
     
    }
    .Sidebar > div {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
    }
    .Sidebar ul li:nth-child(2){
        margin-top: 1rem;
    }
   
  }
  
  /* Extra Small devices (phones) */
  @media (max-width: 467px) {
   
   
  }