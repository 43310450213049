.Tracks{
    min-height: 100vh;
    width: 100%;
    color: var(--primary-color);
    width: 100%;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--background-color);
}

.Tracks h1{
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    color: var(--primary-color);
  
   
}

.tracks-content{
    max-width: 1400px;
    width: 100%;
    margin-top: 6rem;
    text-align: center;
   
  
  
}
.audio-player{
    display: flex;
    align-items: center;
    justify-content: center;
}
.audio-player button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 1px solid var(--primary-color);
    
    border-radius: 50%;
    background-color: transparent;
    padding: 0.6rem;
   
 
   
}
.audio-player a button{
    border-radius: 0.4rem;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Bricolage Grotesque';
    text-decoration: none;
    padding: 0.5rem 1.2rem;
  }
input[type="range"] {
   
   
    height: 6px; 
    width: 200px;
    
    
   
    cursor: pointer; 
   
 
   
    border: 1px solid #ccc;
    
    
    
    
   
    -webkit-appearance: none;
    -moz-apperance: none;
    appearance: none;
   
    background: var(--primary-color) ;
   
}
/* Styling the thumb (button/circle) */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    width: 20px; 
    height: 20px; 
    background-color: var(--background-color); 
    border: 2px solid var(--primary-color); 
    border-radius: 50%; 
    cursor: pointer;
 
}
.progress span{
    margin: 0 1rem;
}


.progress{
    width: 400px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
.track-play{
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.track-play .line{
    width: 300px;
    height: 3px;
    background-color: var(--primary-color);
    margin-left: 1rem;
}


.tracks-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.soundcloud-tracks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 1rem;
}

.tracks-content iframe{
    margin: 3rem 0;
    border-radius: 0.5rem;
  
}

.track{
    margin: 2rem ;
}
.track img{
    width: 250px;
    height: 250px;
    border-radius: 0.6rem;
  
}

.track p{
    font-family: 'Bricolage Grotesque';
    font-size: 18px;
    text-transform: uppercase;
   
}
.track h4{
    font-size: 32px;
    font-family: 'Rubik';
    font-weight: 600;
}
.track-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
}
.line__wrapper{
    position: relative;
}
.track h6, .track h6 span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    
}
.track a{
    color: var(--primary-color);
    
}
.line__wrapper p{
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 0.6rem;
    font-weight: 200;
    font-size: 16px;
  
}
.line__wrapper h6 span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.track__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1400px;
}

.track__details-text{
    text-align: left;
    margin-left: 2rem;
}
.release-date{
    margin-top: auto; 
    font-size: 16px;
    font-weight: 600;
}

.spotify-btn{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
   
}

.Tracks .spotify-btn{
    color: green;
    font-size: 32px;
}

.track-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
}

.share-btn{
    font-family: 'Bricolage Grotesque';
    font-size: 16px;
    font-weight: 200;
    
}

.share-btn span{
    margin-right: .1rem;
}

.audio-control{
    font-size: 18px;
    color: var(--primary-color);
}




  
  
  


/* Small devices (phones) */
@media (max-width: 1200px) {
    .track-details{
        flex-direction: column;

    }
    .track-play .line{
        width: 200px;
     
    }
    .Tracks h1{
        font-size: 22px;
       
    }
    .track h4{
        font-size: 20px;
        margin-top: 1rem;
    }
    .release-date{
        font-size: 14px;
    }
    .track-play{
        margin-left: 0;
        display: none;
    }
    .track-buttons{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .track__wrapper{
        flex-direction: column;
        margin-left: 0;
        align-items: center;
        justify-content: center;
       
    }
    .track__details-text{
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .progress{
        width: 250px;
    }
    .progress input{
        width: 100px;
    }

}