.About{
    /* background:url('../../assets/background3.JPG'); */
    width: 100%;
   min-height: 100vh;
   height: 100%;
    
    color: var(--secondary-color);
    
    background-size: cover;
    scroll-snap-align: start;
   
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff2e2;
   
}
.About h1{
  color: var(--secondary-color);
  font-size: 62px;
}


.about__wrapper{
  
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: calc(100vw - 30px);
    overflow: hidden;
    margin-top: 2rem;

  
    
}

.About span{
  margin: 0 0.5rem;
  font-family: 'Bricolage Grotesque';
  font-weight: 600;
  font-size: 44px;


  display: inline;
  color:var(--primary-color);
 
}





.about__content p{
    line-height: 2.5;
    font-weight: 200;
    z-index: 1;
  
    color: var(--primary-color);
   
}

.about__content-wrapper {
    width: 100%;
    display: flex;

   
    
  }

  .about__content {
    flex: 1;
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 52px;
    position: relative;
   
   
 
  }
  
  .about__image {
    flex: 1;
    max-width: 1300px;
    width: 100%;
      height: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
   
  }
  
  .about__image img {
    width: 120%;
    height: 90vh;
    
    object-fit: cover;
    object-position: bottom center;
    border-bottom-right-radius: 6rem;
    border-top-left-radius: 6rem;


   
   
    
 
  }

  .text-content{
    width: 100%;
    max-width: 800px;
    height: 810px;
    overflow-y: auto;
   
  }
  


.About h1{
    font-size: 44px;
    font-weight: 600;
    font-family: "Playfair Display";
    font-style: italic;
    color: var(--primary-color);
   
}


.About p{
    margin-top: 24px;
    font-size: 14px;
    font-family: 'Poppins';
    max-width: 900px;
    width: 100%;
}




.About .yellow{
  width: 00.5rem;
  height: 4rem;
  background: white;
}

.about-h1{
  display: flex;
 
  
}

.about-h1 h1{
  margin-left: 1rem;
  
}
.text-content h1{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
/* Short Devices */
@media (max-height: 900px){
  .about__wrapper{
    
    height: 100%;
    
  }
  .about__image, .About h1{
    overflow-y: hidden;
  }
 
  .text-content{
    height: calc(100vh - 200px);
    overflow-y: auto ;
  }
  .about__image img{
    object-position: center center;
    width: 100%;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
   
   
   
  }
 
}


/*  Landscape Tablets or Small Laptops*/
@media (min-width: 992px) and (max-width: 1199px) {
  .about__image{
    height: 30vh;
  }
  .about__wrapper{
    flex-direction: column;
    height: 100%;
    overflow-y: auto ;
  }
  .about__image, .About h1{
    overflow-y: hidden;
  }
 
  .text-content{
    height: 300px;
    overflow-y: auto ;
  }
 
  .about__image img{
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: 100%;
    height: 70vh;
  }
}

/* Medium devices (phones) */
@media (min-width: 767px) and (max-width: 991px) {
  .about__image{
    height: 30vh;
  }
  .about__wrapper{
    flex-direction: column;
    height: 100%;
    overflow-y: auto ;
  }
  .about__image, .About h1{
    overflow-y: hidden;
  }
 
  .text-content{
    height: 300px;
    overflow-y: auto ;
  }
 
  .about__image img{
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: 100%;
    height: 70vh;
  }
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .about__wrapper{
    flex-direction: column;
    height: 100%;
    overflow-y: auto !important;
  }
  .about__image, .About h1{
    overflow-y: hidden;
  }
  .about__image img{
    height: 350px;
  }
  .about__image {
    flex: 1;
   
    width: 100%;
    height: 95vh;
    margin-left: 0rem ;
    
   
  }
  .about__content{
    padding: 24px;
  }
}

/* Extra Small devices (phones) */
@media (max-width: 467px) {
  .about__wrapper{
    flex-direction: column;
    
    
  }
  .text-content{
    height: 300px;
    overflow-y: auto ;
    
   
  }
  .about__content{
    justify-content: flex-start;
  }
  .About h1, .About span{
    font-size: 26px;
  }
  .text-content h1{

    align-items: center;
   
  }
  .About p{
    margin-top: 1rem;
  }
  
  
  

  
  
}