.MusicVideos{
    height: 100%;
    width: 100%;
  
    color: white;
    width: 100%;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--background-color);
}

.MusicVideos h1{
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    
    color: var(--primary-color);
}
.MusicVideos a{
    color: grey;
    font-family: 'Bricolage Grotesque';
    margin-top: 1rem;
    text-decoration: underline;
    font-weight: 200;
}

.video-content{
 
    width: 100%;
    margin-top: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Small devices (phones) */
@media (max-width: 768px) {
    .MusicVideos h1{
        font-size: 22px;
    }
    .MusicVideos a {
        font-size: 14px;
    }
}