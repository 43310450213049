.Photos{

    background: var(--background-color);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    flex-direction: column;
    background-size: cover;
    position: relative;
    text-align: center;

}


.Photos h1{
    margin-top: 82px;
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 1rem;

 

}
.photos-wrapper a{
  color: grey;
 
 
  text-decoration: underline;
  font-weight: 200;
  font-family: 'Bricolage Grotesque';
}


.grid {
    column-count: 5;
	column-width: 200px;
	margin-top: 2rem;
    
   
  }

  .grid img{

        width: 100%;
        max-width: 300px;
        margin-bottom: 15px;
        
       
      
        
    
  }
  .photos-wrapper{
    max-width: 1500px;
    width: 100%;
  }
  


 /*  Landscape Tablets or Small Laptops*/
@media (min-width: 992px) and (max-width: 1599px) {
    .photos-wrapper{
        width: calc(100% - 200px);
    }
   
  }
  
  /* Medium devices (phones) */
  @media (min-width: 767px) and (max-width: 991px) {
    .photos-wrapper{
        width: calc(100% - 200px);
    }
   

  }
  
  /* Small devices (phones) */
  @media (max-width: 767px) {
    .photos-wrapper{
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .item{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Photos h1{
      font-size: 22px;
  }
     
      
  }
  
  
  
 
  