.Work{
    min-height: 100vh;
    width: 100%;
    height: 100%;
    color: white;
    width: 100%;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--background-color);
}

.work-content{
    max-width: calc(100vw - 300px);
    
    width: 100%;
    margin-top: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Work h1{
    text-align: center;
    font-family: 'Rubik';
    font-size: 44px;
    font-weight: 600;
    
    color: var(--primary-color);
}
.work-p{
    margin-top: 1rem;
}

.Work p{
   
    color: grey;
    font-family: 'Bricolage Grotesque';
    font-weight: 200;
}

.film-works{

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;

}
.film-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 10px;
    width: 100%;
   
}

.work > div{
    background: #00000053;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    bottom: 0;
    left: 0;
    position: absolute;
    text-align: left;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
 
   
}   
.work > div .work-genre{
    color: var(--background-color);
    font-weight: 400;
    font-size: 28px;
    font-family: 'Bricolage Grotesque';
    margin-left: 2rem;
   
}
.work{
    width: 100%;
    height: 100%;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
   
}

.directed-work, .video-works{
    width: 100%;
}

.work-year{
    font-size: 14px;
    color: grey;
    margin-top: 0.3rem;
    margin-left: 2rem;
}

.Work img, .Work video{
width: 100%;
height: 500px;
    object-fit: cover;
    border-radius: 2rem;
}

@media (max-width: 1000px){
    .work-content{
        max-width: calc(100vw - 50px);
    }
}

/* Small devices (phones) */
@media (max-width: 768px) {
    .Work h1{
        font-size: 22px;
    }
    .work-p{
        font-size: 14px;
    }
    .work-content{
        max-width: calc(100vw - 50px);
    }
    .Work img, .Work video{
        width: 100%;
        height: 180px;
            object-fit: cover;
            border-radius: 0rem;
        }
        .work > div .work-genre{
        font-size: 14px;
        margin-left: 1rem;
    }
    .work-year{
        font-size: 12px;
        margin-left: 1rem;
    }
    .work > div{
      
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        height: 60px;
     
       
    }   
}