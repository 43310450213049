

.Songs{
    width: 100%;
    background-color: #ffefda;
    min-height: 100vh;
    height: 100%;
    background-position: center center;
    background-size: contain;
    position: relative;
    scroll-snap-align: start;

    display: flex;
    align-items: center;
    justify-content: center;
   
    
}

.Songs h1{
    text-align: center;
    font-family: 'Playfair Display';
    font-size: 44px;
    font-weight: 600;
    font-style: italic;
    color: var(--primary-color);
  
    margin-bottom: 2rem;
  
}
.songs__wrapper{
    max-width: 1800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    padding: 12px;
    
}
.songs{
   display: flex;
   align-items: center;
   justify-content: center;
   
    width: 100%;

    padding: 1rem 0;
    overflow: hidden;
   
 
   
}

.song{
   
    width: 850px;
  
   height: 750px;
    transition: all 300ms ease;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    margin-right: 1rem;
    border-radius: 1rem;
   
  
}
.song img{
    width: 850px;
  
   height: 750px;
    
    object-fit: cover;
   
}

.song p{
    font-family: 'Poppins';
    color: white;
    font-size: 16px;
    font-weight: 200;
    margin-top: 1rem;
}

.song button{
    padding: 0.3rem 0.8rem;
    background: transparent;
    border: 1px solid white;
    color: white;
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    margin-top: 1rem;
    font-size: 14px;
    
    text-transform: uppercase;
    
}
.Songs .page-number h1{
    font-family: "Bricolage Grotesque";
    font-size: 62px;
    color: white;
    margin-left: 1rem;
  }
.song button span{
    margin-left: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

  
  .song-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .overlay {
    position: absolute;
   top: 0;
   width: 850px;
  bottom: 0;
   height: 750px;
    
    background-color: rgba(0, 0, 0, 0.5); 
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .song:hover .overlay {
    opacity: 1; 
    
   
  }
  .song:hover {
    width: 1500px; 
  }
  
  .song-details {
    
    
    text-align: center;
    color: white;
    display: none; 
    z-index: 999;
   
   
   
  }
  
  
  .song:hover .song-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  }

  
  

  
  /* Medium devices (phones) */
  @media (min-width: 767px) and (max-width: 991px) {
    .songs{
        flex-direction: column;
    }
    .overlay{
       height: 450px;
        width: 650px;
  
        
    }
    .song{
      border-radius: 1rem;
      justify-content: flex-start;
    }
    
    .song img{
        width: 650px;
       height: 450px;
    }
    
    .song:hover{
        height: 1800px;
        width: 100%;

    }
  
   
   
  }
  
  /* Small devices (phones) */
  @media (max-width: 767px) {
    .songs{
        flex-direction: column;
    }
    .overlay{
       height: 450px;
        width: 350px;
       
        align-items: flex-start;
      
        border-radius: 0;
    }
    .song-details{
      margin-top: 2rem;
    }
    .song{
        border-radius: 0;
        justify-content: flex-start;
       
    }
    .song img{
        width: 350px;
       height: 450px;
    }
    
    .song:hover{
      height: 1800px;
        width: 100%;

    }
    .Songs h1{
        font-size: 26px;
    }
   
     
      
  }
  
  /* Extra Small devices (phones) */
  @media (max-width: 467px) {
  
  
    
  }