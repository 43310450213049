
.Films{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
    color: var(--background-color)
}

.Films h1{
    font-weight: 600;
    font-size: 44px;
    font-family: 'Playfair Display';
    font-style: italic;
    margin-top: 2rem;
    
}


.Films button{
    margin-top: 2rem;
    padding: 0.5rem 1.8rem;
    background: transparent;
  
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
  
    border: 2px solid var(--background-color);
    
    color: var(--background-color);
    display: flex;
    align-items: center;
    z-index: 99;
    border-radius: 50rem;
   
  }
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    width: 100%;
    margin-top: 2rem;
}

.grid-item {
    background-color: #ccc;

    text-align: center;
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.grid-item img, .grid-item video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item:nth-child(3n) {
    grid-column: span 2;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; 
    transition: opacity 0.3s ease;
    
}

.grid-item:hover .video-overlay {
    opacity: 1;
}

.video-overlay p {
    color: white;
   
    text-align: center;
    font-family: 'Bricolage Grotesque';
    font-size: 54px;
}

@media (max-width: 768px){
    .Films h1{
        font-weight: 600;
        font-size: 24px;
        font-family: 'Playfair Display';
        font-style: italic;
        margin-top: 2rem;
        
    }
    .Films video{
        height: 200px;
    }
    .grid-item{
        height: 200px;
    }
    .video-overlay p {
       
        font-size: 24px;
    }
}