.music__wrapper, .film__wrapper{
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  transition: all .5s ease;
  overflow-x: hidden;
  
}
.music__wrapper{
  background-color: #fff2e2;
}
.film__wrapper{
  background-color: #000000;
}

.music__wrapper .name__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100vw - 400px);
  
}
.film__wrapper .name__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100vw - 400px);
}

.music__wrapper h1{
  font-family: 'Rubik';
 
  font-weight: 600;
  font-size: 8vw;
  text-transform: uppercase;
  color: var(--primary-color);
}
.music__wrapper .name__wrapper p{
  font-family: 'Rubik';
  text-transform: uppercase;
  margin-top: 2rem;
  color: var(--primary-color);
  font-size: 1vw;
}
.film__wrapper h1{
  font-family: 'Rubik';
  font-size: 8vw;
  font-weight: 600;
 
  text-transform: uppercase;
  color: var(--background-color);
}
.film__wrapper .name__wrapper p{
  font-family: 'Rubik';
  text-transform: uppercase;
  margin-top: 2rem;
  color: var(--background-color);
 
}


.Home {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    transition: all .5s ease;
    overflow-x: hidden;
   
  
   
    
    
    
   
    
  }
  .Home.music{
   height: 600px;
   width: 100%;
   max-width: calc(100vw - 400px);
   border-radius: 2rem;
  }
  .Home.film{
    width: 100%;
    max-width: calc(100vw - 400px);
    height: 600px;
    border-radius: 2rem;
  }

  
  
.main-film-wrapper{
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  background-image: url("../../assets/film.JPG");
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;


}
.Home button{
  font-family: 'Bricolage Grotesque';
}
.Home .main-song-wrapper button{
  margin-top: 1rem;
  padding: 0.8rem 1.8rem;
  background: transparent;

  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;

  border: 2px solid white;
  
  color: white;
  display: flex;
  align-items: center;
  z-index: 99;
  border-radius: 50rem;
 
}
.Home .main-film-wrapper button{
  margin-top: 1rem;
  padding: 0.8rem 1.8rem;
  background: transparent;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 50rem;
  border: 2px solid white;

  display: flex;
  align-items: center;
}

.Home .main-song-wrapper button span{
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
 
}
.Home .main-song-wrapper button:hover span{
  animation: inOut 0.9s ease-in-out infinite;
}
 
  @keyframes inOut {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .Home .main-film-wrapper button:hover span{
    animation: inOutFilm 0.9s ease-in-out infinite;
  }
 
    @keyframes inOutFilm {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(4px);
      }
      100% {
        transform: translateX(0);
      }
    }
.Home .main-film-wrapper button span{
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.home__footer{
    height: 200px;
    width: 100%;
}

.main-song-wrapper{
  background-image: url("../../assets/photos/ben7.JPG");
  height: 100%;
  width: 100%;
  flex: 1;
  background-size:cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;



}

.main-wrapper{
  display: flex;
  transition: opacity 1.2s ease;
  height: 100%;
  width: 100%;
  


}

/* .logo{
  position: absolute;
  color: white;
  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    writing-mode: vertical-rl; 
    text-orientation:sideways-right; 
    letter-spacing: 6px;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 400;
 
} */
.logo-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
 
}
.logo-wrapper img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32rem;
  height: 130px;
  width: 130px;

}
#logo{
 
  position: relative;
  animation: effect 8s linear infinite;
  height: 200px;
  width: 200px;
  color: white;
  display: flex;
  transition: all 1s ease;
  
 
  
}

@keyframes effect{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
#logo span{
  position: absolute;
  transform-origin: 0 100px;
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 400;
  left: 50%;
}
.main-wrapper p{
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
 
  font-weight: 200;
  font-size: 20px;

 
  margin-top: -0.5rem;
}

.Home .main-song-wrapper .main-name{
 
    opacity: 1;
  
    font-size: 82px;
    letter-spacing: 5px;

    color: transparent;
 
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    z-index: 99;
    font-family: "Rubik";
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
   
  
   
    
}
.main-song-wrapper,
.main-film-wrapper {
  transition: all 2.5s ease;
 
}

.main-song-wrapper.expanded {
  width: 100%;

  
  
}
.main-film-wrapper.hidden{
 
  flex: 0;
  width: 0;
 opacity: 0;
 
}
.main-song-wrapper.hidden{
 
  flex: 0;
  width: 0;
 opacity: 0;
 
}

#logo .hidden {
  opacity: 0;
  width: 0;
  height: 0;
 
}


.main-film-wrapper.expanded {
  width: 100%;
  flex: 6;
}

.Home .main-film-wrapper .main-name{

  opacity: 1;
 
  font-size:82px;

 
  color: white; 
  letter-spacing: 5px;
  font-family: "Rubik";
  font-weight: 600;
 

 
  
}

.main-name{
  transition: all 0.2s ease;
}

.main-name.hidden{
  display: none;
}
.main-song-wrapper button.hidden, .main-film-wrapper button.hidden{
  display: none;
}



.main-song-wrapper .main-background{
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(209, 72, 8, 0);
}

.home__footer{
    height: 200px;
    width: 100%;
}
/* Short Devices */
@media (max-height: 900px){
  .Home .main-song-wrapper .main-name{
    font-size: 62px;
  }
}
/*  Landscape Tablets or Small Laptops*/
@media (min-width: 992px) and (max-width: 1199px) {
  .Home .main-song-wrapper .main-name{
    font-size: 82px;
  }
  .Home.music{

    max-width: calc(100vw - 50px);
    border-radius: 1rem;
   
   }
   .Home.film{
  
     max-width: calc(100vw - 50px);
     border-radius: 1rem;
     
   }
   .music__wrapper .name__wrapper{
   
    max-width: calc(100vw - 50px);
    
  }
  .film__wrapper .name__wrapper{
   
    max-width: calc(100vw - 50px);
  }
}

/* Medium devices (phones) */
@media (min-width: 767px) and (max-width: 991px) {
  .Home .main-song-wrapper .main-name, .Home .main-film-wrapper .main-name{
    font-size: 42px;
  }
  .Home.music{

    max-width: calc(100vw - 50px);
    border-radius: 1rem;
   
   }
   .Home.film{
  
     max-width: calc(100vw - 50px);
     border-radius: 1rem;
     
   }
   .music__wrapper .name__wrapper{
   
    max-width: calc(100vw - 50px);
    
  }
  .film__wrapper .name__wrapper{
   
    max-width: calc(100vw - 50px);
  }
  
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .Home.music, .Home.film{
    height: calc(100vh - 300px);
  }
  .film__wrapper h1, .music__wrapper h1{
    font-size: 11vw;
  }
  .Home .main-song-wrapper .main-name, .Home .main-film-wrapper .main-name{
    font-size: 42px;
  }
  #logo span{
   
    transform-origin: 0 75px;
    font-size: 16px;
   
  }
  #logo{
    height: 150px;
    width: 150px;
  }
  .Home .main-song-wrapper button, .Home .main-film-wrapper button{
    font-size: 12px;
  }
  
  .music__wrapper .name__wrapper p{

    font-size: 2vw;
  }
  .film__wrapper .name__wrapper p{

    font-size: 2vw;
  }
  .Home .main-wrapper{
    flex-direction: column;
  }
  .music__wrapper, .film__wrapper{
    transition: none;
  }
  /* .Home{
    transition: none;
  } */
  .main-wrapper{
    transition: none;
  }
  .main-song-wrapper,
.main-film-wrapper{
  transition: none;
}
.Home.music{

  max-width: calc(100vw - 50px);
  border-radius: 1rem;
 
 }
 .Home.film{

   max-width: calc(100vw - 50px);
   border-radius: 1rem;
   
 }
 .music__wrapper .name__wrapper{
 
  max-width: calc(100vw - 50px);
  
}
.film__wrapper .name__wrapper{
 
  max-width: calc(100vw - 50px);
}
}

/* Extra Small devices (phones) */
@media (max-width: 467px) {
  .Home .main-song-wrapper .main-name, .Home .main-film-wrapper .main-name{
    font-size: 42px;
  }
  .main-wrapper p{
    font-size: 12px;
    margin-top: 1px;
  }
 
}