@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins";
  /* scroll-snap-type: y mandatory; */
  

  
  
  
  
}
body{
  /* scroll-snap-type: y mandatory;
  overflow: hidden; */
  height: 100%;
}




:root{
  /* 1c1000 */
  --primary-color: #2f1e0d; 
  --secondary-color: #5fb762;
  --third-color: #191613;
  --opacity-color: #a4794535;
  --background-color: #fff2e2;
}

::-webkit-scrollbar {
  width: 10px; 

}


::-webkit-scrollbar-thumb {
  background: var(--primary-color); 

  
}

