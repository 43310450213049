.Footer{
    background-color: var(--third-color);
    width: 100%;
    height: 400px;
    color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

 

    
   
}
.footer__content{
   display: grid;
   grid-template-columns: 3fr 1fr 1fr 1fr;
    max-width:calc(100vw - 200px);
    width: 100%;
    
}

.Footer h1{
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 62px;
}
.first-column{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.first-column .footer__socials{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;
}
.footer__socials li{
    list-style: none;
 
}
.footer__socials li a{
    color: var(--background-color);
    font-size: 24px;
    margin-right: 2rem;

}

.second-column, .third-column, .fourth-column{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 1rem;
}
.Footer h5{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Rubik';
}

.second-column a, .third-column a{
    margin-top: 1rem;
    font-family: 'Bricolage Grotesque';
    font-size: 14px;
    font-weight: 200;
    text-decoration: underline;
    color: var(--background-color);
    text-underline-offset: 1px;
}

.Footer input{
    margin-top: 1rem;
    width: 100%;
    font-family: 'Bricolage Grotesque';
    padding: 0.3rem 0rem;
    padding-left: 0.4rem;
}
.Footer button{
    margin-top: 1rem;
  
    padding: 0.4rem 1.8rem;
    background: transparent;
  
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 200;
    font-family: 'Bricolage Grotesque';
    border: 1px solid var(--background-color);
    
    color: var(--background-color);
    display: flex;
    align-items: center;
    z-index: 99;
   
}

.footer__separator{
    height: 2px;
    width: 100%;
    max-width:calc(100vw - 200px);
    margin-top: 2rem;
    background-color: var(--background-color);
}

.Footer .custom-form{
    align-items: start;
}
.Footer .custom-form p{
   font-size: 12px;
   margin-top: 0.4rem;
   font-family: 'Bricolage Grotesque';
}
.Footer .custom-form input{
    font-size: 12px;
}

.footer__copyright{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 200;
    font-family: 'Bricolage Grotesque';
    font-size: 14px;
    width: 100%;
    max-width:calc(100vw - 200px);
}

@media (min-width: 767px) and (max-width: 991px) {
    .Footer h1{
        font-size: 48px;
    }
    .footer__content{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
   
}
/* Small devices (phones) */
@media (max-width: 767px) {
    .footer__content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Footer .custom-form p{
        text-align: center;
     }
    .Footer .custom-form{
        align-items: center;
    }
    .Footer .custom-form{
        width: calc(100vw - 150px);
    }
    .Footer{
        height: 100%;
    }
    .Footer h1{
        font-size: 42px;
        width: 100%;
        text-align: center;
        margin-top: 2rem;
    }
    .first-column{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .first-column .footer__socials{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }
    .second-column, .third-column, .fourth-column{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
    }
    .footer__socials li a{
        color: var(--background-color);
        font-size: 24px;
        margin-right: 0.5rem;
    
    }
    .footer__content, .footer__separator, .footer__copyright{
     
         max-width:calc(100vw - 50px);
         width: 100%;
         flex-direction: column;
         font-size: 14px;
         
     }
     .footer__copyright p{
        margin-top: 0.3rem;
     }
  }
  
  /* Extra Small devices (phones) */
  @media (max-width: 467px) {
  
   
  }