.modal {
   
    width: 500px;
    height: max-content;
    padding: 4rem 3rem;
    background-color: var(--primary-color);
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    outline: none;
    color: white;
    box-shadow: 0px 2.98px 7.46px 0px rgba(0, 0, 0, 0.1);
    animation: fade-in 0.2s ease;
    font-family: "Bricolage Grotesque";
   
  }
  
  .modal-overlay {
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(71, 71, 71, 0.734);
    z-index: 997;
    flex-grow: 1;
    animation: fade-in 0.2s ease;
  }

  .modal h1{
    font-size: 36px;
    font-weight: 600;
    font-family: "Bricolage Grotesque";
  }
  .cross{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 32px;
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  .modal p{
    font-size: 14px;
    font-weight: 200;
    margin-top: 0.8;
    text-align: center;
  }

  .modal input{
    font-size: 14px;
    font-weight: 200;
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid white;
    margin-top: 4rem;
    width: calc(100% - 10px);
    color: white;
    padding: 0.4rem 1rem;
  }

  .modal input:focus{
    border: none
  }
  .custom-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .modal button{
    margin-top: 2rem;
    width: 100%;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    font-size: 16px;
    padding: 0.2rem 0.8rem;
    font-weight: 200;
    text-transform: uppercase;
  }
  .message{
    margin-top: 1rem;
  }
  .sending{
    color: rgb(247, 247, 113);
  }
  .submit{
    color: rgb(0, 199, 0);
  }
  .error{
    color: red;
  }

  .modal .hidden{
    display: none;
  }
  
  /* Extra Small devices (phones) */
  @media (max-width: 500px) {
   .modal{
    width: calc(100vw - 30px);

   }
   .modal h1{
    font-size: 24px;
   }
   .modal p{
    font-weight: 200;
    font-size: 12px;
   }
   .modal input{
    font-size: 12px;
    padding: 0.1rem 0.6rem;
   }

    
  }